
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import Insurer from "@/components/reusable/Insurer.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "finance-credit-notes-reporting",
  components: { Field, ErrorMessage, Insurer },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      isEmptyArray,
    } = ResusableFunctions();

    const creditNotes = ref();
    const underwriters = ref([]);
    const currentDate = variables.currentDate();
    const today = new Date();
    const startDate = today.getFullYear() + "-" + "01" + "-" + "01";
    const payload = ref({
      type: "",
      underwriterPublicId: "",
    });

    const getUnderwriters = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.FINANCE_UNDERWRITERS_ROUTE)
          // ApiService.get(
          //   `${variables.FINANCE_CREDIT_NOTES_ROUTE}${variables.UNDERWRITER_ROUTE}`
          // )
          .then(({ data }) => {
            underwriters.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const capitalizeWord = (value) => {
      return variables.capitalizeWord(value);
    };

    const addCommaToNumber = (amount) => {
      return variables.addCommaToNumber(amount);
    };

    const addCommaToNumberString = (amount) => {
      return variables.addCommaToNumberString(amount);
    };

    const formatDate = (dateString: string) => {
      return variables.formatDate(dateString);
    };

    const getUnderwriterId = (underwriterId: string) => {
      payload.value.underwriterPublicId = underwriterId;
    };

    const getCreditNotes = (type: string) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.FINANCE_CREDIT_NOTES_ROUTE}${variables.UNDERWRITER_ROUTE}/${payload.value.underwriterPublicId}/${type}`
        )
          .then(({ data }) => {
            creditNotes.value = data?.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    onMounted(() => {
      getUnderwriters();

      setCurrentPageBreadcrumbs("Credit Notes Reporting", [
        "Finance",
        "Credit Notes",
      ]);
    });

    return {
      reinitialization,
      useReusableNavigation,
      capitalizeWord,
      addCommaToNumber,
      addCommaToNumberString,
      formatDate,
      getUnderwriterId,
      isEmptyArray,
      getCreditNotes,
      underwriters,
      creditNotes,
      payload,
      currentDate,
      startDate,
    };
  },
  created() {
    //Set page title
    document.title =
      "Finance: Credit Notes Reporting | " + process.env.VUE_APP_TITLE;
  },
});
